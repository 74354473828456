<template>
  <div class="content-wrap">
    <div class="filters">
      <!-- 成绩状态 -->
      <ListFilter
        only
        :name="$t('train.grade_status')"
        :dataSource="pass"
        @onChange="passChange"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value.trim="queryParam.keyword"
              :placeholder="$t('please_enter_keywords')"
              style="width: 240px"
              @search="reload"
            />
            <!-- 请输入关键字 -->
          </div>
        </template>
      </ListFilter>
    </div>
    <div class="table-wrap">
      <a-table
        size="middle"
        :scroll="{ x: 854 }"
        :columns="columns"
        :data-source="state.dataSource"
        rowKey="userId"
        :bordered="false"
        :pagination="pagination"
        :loading="state.loading"
        @change="handleTableChange"
      >
        <template #realName="{ record }">
          <div class="td-account">
            <div class="portrait">
              <img :src="record.portrait" alt="portrait" />
            </div>
            <div class="text">
              <p>{{ record.account }}</p>
              <p>
                <OpenData type="userName" :openid="record.realName" />
              </p>
            </div>
          </div>
        </template>
        <template #passStatus="{ record }">
          <span v-if="record.examUid > 0">
            {{ record.examScore > data.examPass ? $t('teaching.qualified') : $t('teaching.unqualified') }}
            <!-- "合格" : "不合格" -->
          </span>
        </template>
        <template #examScore="{ record }">
          <span class="td-score" v-if="record.examUid > 0"
            ><i>{{ record.examScore }}</i> 分</span
          >
        </template>
        <template #action="{ record }">
          <div class="action">
            <a-button
              type="link"
              v-if="record.examUid > 0"
              @click="showScore(record, 'show')"
              >{{ $t('train.view_grade') }}</a-button
            >
            <!-- 查看成绩 -->
            <a-button type="link" v-else @click="showScore(record, 'save')"
              >{{ $t('train.enter_grades') }}</a-button
            >
            <!-- 录入成绩 -->
          </div>
        </template>
      </a-table>
    </div>
  </div>

  <a-modal
    v-model:visible="modalVisible"
    :title="modalData[modalType] ? modalData[modalType].title : ''"
    :confirmLoading="modalLoading"
    width="700px"
    @ok="modalType == 'save' ? modalOk() : (modalVisible = false)"
    @cancel="modalVisible = false"
    destroyOnClose
  >
    <template v-if="modalType == 'save'">
      <p>
        {{ $t('train.enter_grades_for', 1) }} 
        <!-- 当前正在为 -->
        <OpenData type="userName" :openid="currUser.realName" /> 
        {{ $t('train.enter_grades_for', 2) }}
        <!-- 录入成绩 -->
      </p>
      <a-form
        layout="horizontal"
        class="check-form"
        ref="scoreFormRef"
        :model="modalData.save.formData"
      >
      <!-- 分数 -->
        <a-form-item
          :label="$t('homework.score')"
          name="examScore"
          :rules="{
            required: true,
            type: 'number',
            message: $t('homework.please_fill_in_the_score'),
            // 请填写分数
            trigger: 'change',
          }"
        >
          <a-input-number
            v-model:value="modalData.save.formData.examScore"
            :min="0"
            :max="data.examScore"
            :precision="1"
            style="width: 300px"
          />
          &nbsp;&nbsp;{{ $t('homework.full_score') }}：{{ data.examScore }}
          <!-- 满分： -->
        </a-form-item>
        <!-- 评语 -->
        <a-form-item :label="$t('homework.comments')" name="examRemark">
          <a-textarea
            v-model:value="modalData.save.formData.examRemark"
            :rows="3"
          />
        </a-form-item>
      </a-form>
    </template>
    <template v-if="modalType == 'show'">
      <p class="row">
        {{ $t('train.student') }}：<OpenData type="userName" :openid="currUser.realName" />
        <!-- 学员： -->
      </p>
      <p class="row">{{ $t('cm_account') }}：{{ currUser.account }}</p>
      <!-- 账号： -->
      <p class="row">{{ $t('train.grades') }}：{{ currUser.examScore }} {{ $t('exam.score') }}</p>
      <!-- 成绩： -->
      <p class="row">
        <!-- 合格状态： -->
        {{ $t('train.qualified_status') }}：{{ currUser.examScore > data.examPass ? $t('teaching.qualified') : $t('teaching.unqualified') }}
        <!-- "合格" : "不合格" -->
      </p>
      <p class="row">{{ $t('homework.comments') }}：{{ currUser.examRemark }}</p>
      <!-- 评语： -->
    </template>
  </a-modal>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { reactive, toRefs, ref, inject, getCurrentInstance } from "vue";
import { useFetchData } from "@/utils/useFetchData";
import { trainMembers, trainMembersScore } from "@/api/teacher";
import ListFilter from "@/components/filter/ListFilter.vue";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    ListFilter,
    OpenData,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    taskId: {
      type: Number,
      default: 0,
    },
    detailId: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const stateData = reactive({
      pass: [
        { id: 1, name: $t('teaching.qualified') }, // 已合格
        { id: 2, name: $t('teaching.unqualified') }, // 未合格
        { id: 3, name: $t('train.not_entered') }, // 未录入
      ],
      columns: [
        {
          title: $t('train.student'),
          // title: "学员",
          width: 310,
          slots: { customRender: "realName" },
        },
        {
          title: $t('train.grades'),
          // title: "成绩",
          dataIndex: "examScore",
          width: 120,
          slots: { customRender: "examScore" },
          align: 'right',
        },
        {
          title: $t('train.pass_status'),
          // title: "及格状态",
          width: 220,
          slots: { customRender: "passStatus" },
          align: 'center',
        },
        {
          title: $t('cm_operate'),
          // title: "操作",
          dataIndex: "action",
          width: 100,
          slots: { customRender: "action" },
        },
      ],
      modalType: "",
      modalVisible: false,
      modalLoading: false,
      modalData: {
        save: {
          title: $t('train.enter_grades'),
          // title: "录入成绩",
          formData: {
            examScore: 0,
            examRemark: "",
          },
        },
        show: {
          title: $t('train.view_grade'),
          // title: "查看成绩",
        },
      },
      currUser: null,
    });

    const queryParam = reactive({
      taskId: props.taskId,
      detailId: props.detailId,
      keyword: "",
      isPass: 0,
    });

    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return trainMembers(form).then((res) => {
        upScore({
          scoreNum: res.data.scoreNum,
          notScoreNum: res.data.notScoreNum,
        })
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };

    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 10,
      tableSize: "middle",
      stripe: false,
    });

    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });
      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["10", "30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    const passChange = (arr) => {
      queryParam.isPass = arr[0];
      reload();
    };

    const showScore = (item, type) => {
      stateData.modalType = type;
      stateData.modalVisible = true;
      stateData.currUser = item;
      stateData.modalData.save.formData = {
        examScore: 0,
        examRemark: "",
      };
    };

    const scoreFormRef = ref(null);
    const modalOk = () => {
      scoreFormRef.value.validate().then(() => {
        stateData.modalLoading = true;
        let formData = stateData.modalData.save.formData;
        trainMembersScore({
          taskId: props.taskId,
          detailId: props.detailId,
          ids: [stateData.currUser.userId],
          examScore: formData.examScore,
          examRemark: formData.examRemark,
        }).then((res) => {
          proxy.$message.success($t('train.entered_successfully'));
          // 录入成功
          stateData.modalLoading = false;
          stateData.modalVisible = false;
          reload();
        });
      });
    };

    const parentData = inject('upData');
    const upScore = data => {
      parentData.upScore(data);
    };

    return {
      ...toRefs(stateData),
      state,
      pagination,
      handleTableChange,
      queryParam,
      reload,
      passChange,
      showScore,
      scoreFormRef,
      modalOk,
    };
  },
};
</script>

<style lang="less" scoped>
.content-wrap {
  background-color: #fff;
  padding: 0 24px 14px;
  .filters {
    ::v-deep(.filter) {
      .label {
        width: 78px !important;
      }
      .values {
        width: calc(100% - 88px) !important;
      }
    }
    .search {
      .mixinFlex(flex-end);
    }
  }
  .table-wrap {
    min-height: 756px;
    margin-top: 10px;
    ::v-deep(td) {
      vertical-align: middle !important;
    }
    ::v-deep(.ant-table-pagination.ant-pagination) {
      float: none;
      text-align: center;
    }
    .action {
      ::v-deep(.ant-btn) {
        padding-right: 4px;
        padding-left: 0;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .td-account {
      .mixinFlex(space-between; center);
      .portrait {
        .mixinImgWrap(40px; 40px);
        border-radius: 50%;
      }
      .text {
        width: calc(100% - 50px);
        p {
          margin: 0;
          font-size: 14px;
          color: #666;
          line-height: 20px;
        }
      }
    }
    .td-score {
      color: @color-theme;
      font-size: 16px;
      i {
        font-size: 30px;
        font-style: normal;
      }
    }
  }
}
.row {
  font-size: 16px;
  margin-bottom: 10px;
}
</style>