import request from '@/utils/request';

// 获取线下培训
export const trainCourse = params => request.post(`lecturer/train/traincourse`, params)

// 获取线上课程
export const onlineCourse = params => request.post(`lecturer/lecturer/onlinecourse`, params)

// 课程日程
export const trainCalendar = params => request.post(`lecturer/lecturer/courseschedule`, params)

// 直播课程
export const liveCourse = params => request.post(`lecturer/live/list`, params)

// 开始直播
export const startLive = detailId => request.get(`lecturer/live/${detailId}/start`)

// 获取讲师详情
export const getTeacher = () => request.get(`lecturer/me/detail`)

// 编辑讲师详情
export const editTeacher = params => request.post(`lecturer/me/editprofile`, params)

// 获取讲师聘期列表
export const teacherTenure = () => request.get(`lecturer/lecturer/tenure/list`)

// 编辑讲师荣誉列表
export const teacherHonor = params => request.post(`lecturer/lecturer/honor/list`, params)

// 讲师申请列表
export const teacherApplyList = params => request.get(`lecturer/lecturer/apply/list`, params)

// 讲师申请详情
export const teacherApplyDetail = id => request.get(`lecturer/lecturer/apply/${id}/detail`)

// 讲师申请
export const lecturerApply = params => request.post(`study/lecturer/apply`, params)

// 个人中心讲师申请列表
export const lecturerApplyList = params => request.get(`study/lecturer/apply/list`, params)

// 个人中心讲师申请详情
export const lecturerApplyDetail = id => request.get(`study/lecturer/apply/${id}/detail`)

// 讲师目录
export const lecturerFolder = params => request.get(`study/lecturer/folder/tree`, params)

// 讲师等级
export const lecturerLevel = params => request.get(`study/lecturer/level`, params)

// 考勤列表
export const trainMembers = params => request.post(`lecturer/train/${params.taskId}/${params.detailId}/members`, params)

// 批量设置考勤
export const trainMembersSubmit = params => request.post(`lecturer/train/attendance/submit`, params)

// 获取评价问卷
export const trainMembersQuestionnaire = questionnaireId => request.get(`lecturer/train/questionnaire/${questionnaireId}/details`)

// 获取评价问卷提交详情
export const trainMembersQuestionnaireResult = params => request.get(`lecturer/train/${params.taskId}/detail/${params.detailId}/questionnaire/${params.questionnaireId}/user/${params.userId}/submit/detail`)

// 讲师评价学员提交
export const trainMembersQuestionnaireSubmit = (ids,params) => request.post(`lecturer/train/questionnaire/${ids.taskId}/${ids.detailId}/${ids.userId}/${ids.questionnaireId}/submit`, params)

// 录入成绩
export const trainMembersScore = params => request.post(`lecturer/train/exam/score`, params)

// 活动附件上传
export const trainMembersUpload = params => request.post(`lecturer/train/${params.taskId}/${params.detailId}/attachments/submit`, params)

// 面授导入考勤
export const importAttendance = params => request.post(`lms/project/train/attendence/import`, params)
